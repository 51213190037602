<!--
 * @Author: dongjia
 * @Date: 2022-04-19 14:02:57
 * @LastEditTime: 2022-04-19 14:39:13
 * @LastEditors: aleaner
 * @Description: 企业微信授权二维码
 * @FilePath: \super-admin-vue\src\modules\user-index\components\AuthQrCodeDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="企业微信授权" :visible.sync="openDialog" width="280px"
             class="dialog-vertical" close-on-click-modal close-on-press-escape>
    <div class="main-content">
      <el-image class="qr-code" :src="qr_code" fit="cover" v-loading="loading"></el-image>
      <p class="refreash-page">请使用企业微信扫一扫，<el-button type="text" size="small" @click="getQrCode">刷新二维码</el-button>
      </p>
      <div class="refreash-page">我已授权企业微信，请 <el-button type="text" size="small" @click="refreashPage">刷新页面
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { authQrCode } from "../api/user-index";
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      qr_code: "",
    };
  },
  methods: {
    // 获取企微授权二维码
    getQrCode() {
      this.loading = true;
      authQrCode()
        .then((res) => {
          this.qr_code = res.data.qrcode;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 页面刷新事件
    refreashPage() {
      location.reload();
    },
  },
  created() {},
  watch: {
    openDialog(val) {
      if (val) {
        this.getQrCode();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    // 项目服务到期提示数据
    serverTips() {
      return this.$store.getters["header/headerData"].tips;
    },
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  .qr-code {
    width: 200px;
    height: 200px;
  }
  .refreash-page {
    margin-top: 12px;
    font-size: 12px;
    line-height: 1;
  }
  .el-button {
    padding: 0;
  }
}
</style>
