import api from "@/base/utils/request";

// 获取
export const setMyInfo = data => {
    return api({
        url: "/admin/admin/User/userInfo",
        method: "post",
        data
    });
};

// 保存
export const saveMyInfo = data => {
    return api({
        url: "/admin/admin/user/saveUserInfo",
        method: "post",
        data
    });
};

export const unbindWxqy = () => {
  return api({
    url: '/admin/admin/user/unbindWxqy',
    method: 'post'
  })
}

export const authQrCode = () => {
  return api({
    url: '/admin/admin/user/wxqyAuthQrcode',
    method: 'post'
  })
}

// 生成二维码
export const getQrcode = data => {
  return api({
      url: "/admin/admin/tool/qrcode",
      method: "post",
      data
  });
};
