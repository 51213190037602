<template>
  <div class="user-index-info reset-btn-page" v-loading="loading">
    <div class="content">
      <el-form
        size="small"
        :model="basicForm"
        label-position="right"
        label-width="80px"
        class="small-form"
      >
        <p class="config-section-title">基本信息</p>
        <el-form-item class="user-avatar">
          <SingleMediaWall
            v-model="basicForm.avatar"
            :showMask="false"
            :width="150"
            :height="150"
            :useDefaultAvatar="true"
            :borderRadius="100"
            addText="上传头像"
          >
          </SingleMediaWall>
        </el-form-item>
        <el-form-item label="账号：">
          <el-input v-model="basicForm.account" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="姓名：">
          <el-input
            v-model="basicForm.nickname"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别：">
          <el-radio-group v-model.number="basicForm.gender">
            <el-radio :label="0">保密</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="2">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="邮箱：">
          <el-input v-model="basicForm.email" placeholder="请输入"></el-input>
        </el-form-item>
        <!--        <el-form-item label="所属部门：">-->
        <!--          <el-input v-model="basicForm.department" placeholder="请输入"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="联系电话：">
          <el-input v-model="basicForm.phone" placeholder="请输入"></el-input>
        </el-form-item>
<!--        <p class="config-section-title">第三方平台授权</p>-->
        <el-form-item v-if="false" label="企业微信授权：" label-width="100px">
          <el-button
            type="primary"
            size="small"
            class="auth-btn"
            v-if="basicForm.is_bind_wxqy !== 1"
            :disabled="basicForm.is_bind_wxqy === -1"
            @click="showAuthQrCode = true"
            >点击授权
          </el-button>
          <!-- 企业授权：-1 不开启 0 未绑定 1 已绑定 -->
          <div class="auth-success" v-if="basicForm.is_bind_wxqy === 1">
            <span style="color: #606266">已授权</span>
            <span class="el-icon-success"></span>
            <el-button class="cancel-auth-btn" type="text" @click="cancelAuth"
              >取消授权</el-button
            >
          </div>
          <!-- 已开启「已安装应用」提示 -->
          <div v-if="basicForm.is_bind_wxqy !== -1" class="info">
            <i class="el-icon-info" />
            <span>授权后可在企业微信管理后台功能</span>
          </div>
          <div v-else class="info">
            <i class="el-icon-info" />
            <span v-if="headerData && headerData.admin_user.is_super_admin">
              <!-- 用户中心地址： /admin/my-project/manage/qMWaj9Zv/basic_settings -->
              请先在<a
                @click="toProjectDetail"
                class="cursor-pointer"
                target="_blank"
                >项目详情</a
              >授权绑定企业微信，授权后可在企业微信管理后台功能
            </span>
            <span v-else
              >缺少访问权限，请联系超级管理员前往项目详情授权绑定企业微信</span
            >
          </div>
        </el-form-item>
        <p class="config-section-title">移动端工作台</p>
        <div class="MobileCode" v-if="mobileQrCode">
          <img :src="mobileQrCode" />
        </div>
        <el-link
          style="margin-left: 12px"
          type="primary"
          @click="copyLink"
          :underline="false"
          >复制链接</el-link
        >
      </el-form>
      <AuthQrCodeDialog v-model="showAuthQrCode" />
      <fixed-action-bar>
        <el-button type="primary" @click="handleSave">保存</el-button>
        <el-button @click="back">取消</el-button>
      </fixed-action-bar>
    </div>
  </div>
</template>

<script>
import { setMyInfo, saveMyInfo, getQrcode } from "../api/user-index";
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import FixedActionBar from "@/base/layout/FixedActionBar";
import { unbindWxqy } from "@/modules/user/api/user-index";
import AuthQrCodeDialog from "@/modules/user/components/AuthQrCodeDialog";
import { copyText } from "@/base/utils/tool.js";

export default {
  components: {
    AuthQrCodeDialog,
    FixedActionBar,
    SingleMediaWall,
  },
  data() {
    return {
      loading: true,
      basicForm: {
        account: "",
        nickname: "",
        real_name: "",
        gender: 1,
        avatar: "",
        email: "",
        department: "",
        phone: "",
        // -1：未开启，0：未绑定，1：已绑定
        is_bind_wxqy: 0,
      },
      showAuthQrCode: false,
      // 移动端工作台二维码
      mobileQrCode: "",
    };
  },
  computed: {
    // header数据
    headerData() {
      return this.$store.getters["header/headerData"];
    },
  },
  created() {
    this._workstation_url = (window.serverConfig.VUE_APP_OTHER_HEADERS['debug-saas-host']||(window.location.protocol + "//" + window.location.host)) + "/h5/admin/";

    this.getPersonalInfo();
    this.getMobileQrcode();
  },
  methods: {
    // 复制链接
    copyLink() {
      let url = this._workstation_url
      copyText(url)
        .then((res) => {
          this.$message.success("复制成功");
        })
        .catch((e) => {});
    },
    // 获取移动端工作台二维码
    getMobileQrcode() {
      let url = this._workstation_url
      getQrcode({ content: url }).then((res) => {
        this.mobileQrCode = res.data.qrcode;
      }).catch(err => {

      })
    },
    toProjectDetail() {
      // this.handleJumpUserAdmin("MyProjectManage", {
      //   id: this.$store.state.tenant.id,
      //   type: 'basic_settings'
      // });
      this.$router.push({
        name: "MyProjectManage",
        params: {
          id: this.$store.state.tenant.id,
          type: "basic_settings",
        },
      });
    },
    // 解绑企业微信
    cancelAuth() {
      unbindWxqy()
        .then((res) => {
          this.$message.success(res.msg);
          this.getPersonalInfo();
        })
        .catch(() => {
          this.getPersonalInfo();
        });
    },
    // 获取个人信息
    getPersonalInfo() {
      setMyInfo()
        .then((res) => {
          res.data.avatar = res.data.avatar || "";
          this.basicForm = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击保存
    handleSave() {
      this.loading = true;
      saveMyInfo(this.basicForm)
        .then((res) => {
          this.$message.success(res.msg);
          this.$store.commit("header/setIsRefresh", true);
          this.$store.commit("setAvatar", this.basicForm.avatar);
          this.$store.commit("setNickname", this.basicForm.nickname);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    back() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
.user-index-info {
  position: relative;

  .small-form {
    max-width: 400px;
  }

  .user-avatar {
    position: absolute;
    margin-top: 20px;
    margin-left: 400px;

    .el-image {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 1px solid #cccccc;

      ::v-deep .el-image__error {
        display: none;
      }
    }

    .change-img {
      font-size: 18px;
      display: block;
      width: 100px;
      text-align: center;
    }
  }

  .auth-success {
    display: flex;
    align-items: center;
    .el-icon-success {
      color: #67c23a;
      font-size: 16px;
      margin-left: 8px;
      flex-shrink: 0;
    }
    .cancel-auth-btn {
      color: #f56c6c;
      font-size: 14px;
      margin-left: 8px;
    }
  }
}
</style>
